import { useSSRSelector } from '@/redux/ssrStore';
import { DynamicComponent } from './dynamicComponent';
import { getSizeAccType } from './getSizeAccType';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { PATH, getRoute } from 'src/utils/routes';
import { useRouter } from 'next/router';

export const LogoRenderer = (props: any) => {
  const router = useRouter();
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const {
    activeHeaderData,
    showDynamicSection = false,
    isScroll = false,
    currentClass,
    globalClass,
  } = props;
  const {
    headerPropsConfig: { logo_section, desktopHeader, mobileHeader, dynamic_section },
  } = activeHeaderData;
  const { staticLogo, scrollLogo } = logo_section;
  const currentType = isScroll ? scrollLogo : staticLogo;
  const isTransparent =
    globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';
  const {
    base_config,
    componentData: { url },
  } =
    scrollLogo?.componentData?.url?.length > 0 && isTransparent
      ? currentType
      : staticLogo;
  const currentSize = isMobile
    ? base_config.defaultMobileSize
    : base_config.defaultDesktopSize;
  const selectedClass = isTransparent ? currentClass : globalClass?.staticClass;
  const store = useSSRSelector((state) => state.storeReducer.store);
  const storeName = store?.store_info?.name;

  function getNavElementStyle() {
    return {
      fontSize: getSizeAccType(
        globalClass.staticClass.base_config.defaultTextFontSize,
        isMobile
          ? globalClass.staticClass.base_config.defaultFontSizeTypeMobile
          : globalClass.staticClass.base_config.defaultFontSizeType
      ),
      fontWeight: globalClass.staticClass.base_config.defaultTextFontWeight,
      color: selectedClass?.base_config.defaultNavLinksColor,
      textTransform: globalClass.staticClass.base_config.defaultTextDecoration,
    };
  }

  function getImageLayoutSize() {
    const splitAspectRatio =
      logo_section?.staticLogo?.componentData?.aspectRatio?.split(' / ');
    if (!splitAspectRatio) {
      return {};
    }
    const aspectRatio = splitAspectRatio[0] / splitAspectRatio[1];
    const width = currentSize;
    return { width: `${width}px`, height: `${width * (1 / aspectRatio)}px` };
  }

  const handleLogoClick = () =>
    router.push(getRoute(PATH.PRODUCT, store?.store_info?.domain));

  // function getWidth() {
  //   if (base_config.showStoreName && storeName.length) {
  //     return currentSize;
  //   } else {
  //     return currentSize;
  //   }
  // }
  // if (!base_config.showLogo && !base_config.showStoreName) {
  //   return null;
  // }

  return (
    <div
      className={classNames(
        'tw-flex',
        !base_config.showLogo && !base_config.showStoreName && 'tw-w-0 tw-overflow-hidden'
      )}
      style={{ scrollbarWidth: 'none' }}
    >
      <div
        style={{
          justifyContent: base_config.defaultPosition,
          cursor: 'pointer',
          // width: `${getWidth()}px`,
        }}
        className={`tw-flex tw-items-center tw-gap-[10px] ${logo_section.customClass}`}
      >
        {!!base_config.showLogo && !!url && (
          <img
            onClick={handleLogoClick}
            src={url}
            {...getImageLayoutSize()}
            className={`tw-object-contain ${isMobile ? (mobileHeader === 'header6' ? 'tw-relative !tw-right-[10px] !tw-max-w-[144px]' : 'tw-relative !tw-left-[0px] !tw-max-w-[144px]') : ''}`}
            style={
              desktopHeader === 'header3' &&
              dynamic_section?.base_config?.defaultPosition === 'right' &&
              !isMobile
                ? { position: 'relative', left: '9.9px' }
                : {}
            }
          />
        )}
        {base_config.showStoreName && (
          <div
            style={getNavElementStyle()}
            className={twMerge(
              // ' tw-w-[160px]',
              !!base_config.showLogo && !!url && 'tw-ml-[8px]'
            )}
          >
            {storeName}
          </div>
        )}
        {showDynamicSection && (
          <div className="tw-ml-[20px]">
            <DynamicComponent currentClass={selectedClass} globalClass={globalClass} />
          </div>
        )}
      </div>
    </div>
  );
};
